import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import InspirationItem from '../../components/inspiration/InspirationItem';
import Layout from '../../components/layouts/core';
import SeriesPage from '../../components/series/SeriesPage';

import nuBleuII from '../../data/CollectionItems/nuBleuII';

import nuBleuIICarouselPiece from '../../data/CarouselPieces/nuBleuIICarouselPiece';

import getImagesFromNodes from '../../utils/getImagesFromNodes';

export const pageQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/nu-bleu-ii-matisse-print/" } }) {
      nodes {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

const ogDescription = `Boogie-Woogie is an NFT collection from Dandy Lines.
In our Run 1 Matisse series, we represent a piece from his Nu Bleu, or Blue Nudes series.
The simplicity of form and the large, uninterrupted organic shapes allow for large, high-contrast
blocks, thus creating a great opportunity for viewer interpretation and engagement`;

const RunOneMatissePage = ({ data }) => {
  const images = getImagesFromNodes(data.allFile.nodes);

  return (
    <>
      <Helmet>
        <title>Boogie-Woogie | Run 1 Matisse Series</title>
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@BoogieWoogieNFT' />
        <meta name='twitter:creator' content='@ics_jim' />
        <meta property='og:url' content='https://boogie-woogie.io/series/run-1-matisse' />
        <meta property='og:title' content='Boogie-Woogie | Run 1 Matisse Series' />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={ogDescription} />
        <meta property='og:image' content='https://sale.boogie-woogie.io/boogie-woogie-auction-site-run-one.png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Helmet>
      <Layout>
        <SeriesPage details={nuBleuII.details} seriesName='Run 1 Matisse Series' images={images}>
          <InspirationItem
            ReferenceImage={nuBleuII.ReferenceImage}
            details={nuBleuII.details}
            seriesPage={nuBleuII.seriesPage}
            invert
            piece={nuBleuIICarouselPiece}
          />
        </SeriesPage>
      </Layout>
    </>
  );
};

export default RunOneMatissePage;
